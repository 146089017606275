const pictures ={

    logo:"./img/logowhite.png",
    Picture01:"./img/picture01.jpg",
    Picture02:"./img/picture02.png",
    Picture03:"./img/picture03.png",
    Picture04:"./img/picture04.jpg",
    Picture05:"./img/picture05.jpg",
    Vetor01:"./img/vetor01.png",
    Vetor02:"./img/vetor02.png",
    Missao:"./img/arrow.png",
    Valor:"./img/mission.png",
    News3:"./img/news3.jpg",
    News4:"./img/news4.png",
    News5:"./img/news5.jpg",
    News7:"./img/news7.jpg",
    Here:"./img/here.png",
    Company:"./img/company.png",
    First:"./img/company-001.png",
    Futuro:"./img/fu.png",
    Multiplanos:"./img/multi.png",
    Grid:"./img/grid.png",
    Grid2:"./img/grid2.png",
    Servico1:"./img/service01.png",
    Servico2:"./img/service02.png",
    Servico3:"./img/service03.png",
    Contacto:"./img/contacto.jpg",
    We:"./img/we.jpg",
    Smile:"./img/smile.png",
    Team:"./img/team.png",
    Visao:"./img/visao.png",
    Senior:"./img/senior.png",
    Preloader:"./img/preloader.gif",
    Organograma:"./img/organograma.png",
   
   
}

export default pictures