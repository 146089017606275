import React,{Component} from 'react'

class News extends Component{

    render(){

        return(
            <React.Fragment>
                 <div className="col l4 card-post s12"
                     data-aos="fade-up"
                     data-aos-duration="1800"
                     key={this.props.key}
                 >

                        <div className="limite">

                            <div className="cat-post">
                                    <p>{this.props.categoria}</p>
                            </div>

                            <div className="cover-post">
                                <img src={this.props.cover} alt="post-cover" className="post-cover"/>
                                
                            </div>
                            <div className="det-post">
                               {/* <p><i className="fa fa-calendar" style={{marginRight:'16px'}}></i>{this.props.data}</p> */}
                               
                                        <p className="title-post">
                                        <a href={this.props.link} target="_blank" rel="noopener noreferrer">
                                            {this.props.titulo}
                                            </a>
                                        </p>
                

                                    <a href={this.props.link} className='link-read' target="_blank" rel="noopener noreferrer">
                                        Ver o documento ...
                                    </a>
                                
                            </div>
                        
                        </div>

                 </div>
            </React.Fragment>
        )
    }
}

export default News