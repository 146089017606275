import React,{ useState } from 'react' 
import './post.css'
import pictures from '../../pictures'
import AOS from 'aos'
import '../../styles/aos-css/aos.css'
import documents from '../../services/lists'
import List_2024 from '../../data'
//import {animateScroll as Scroll} from 'react-scroll'
//import { Link } from 'react-router-dom'

import News from "../../components/news/news"


function PostPage(props){

    const [year, setYear] = useState(2022)
    const [type, setType] = useState('document')
    const filterDocuments = documents.filter((doc)=> doc.year.toString().indexOf(year) > -1);
   
    function GetAllPost(){
        return (

        filterDocuments.map(post=>{
             
            return(
                <News
                    key={post.id} 
                    categoria ={post.Categoria}
                    titulo ={post.Titulo}
                    cover ={post.Cover}
                    link={post.link}
              />
            )
        })

        )
    } 


    function getList2024(){
        return (

        List_2024.map(post=>{
             
            return(
                <News
                    key={post.id} 
                    categoria ={post.Categoria}
                    titulo ={post.Titulo}
                    cover ={post.Cover}
                    link={post.link}
              />
            )
        })

        )
    } 

   


    /*function GetPostAndScrollTop(){
        Scroll.scrollToTop() 
    } */


     AOS.init()

        return(

            <React.Fragment>

                <div className="row post" style={{marginBottom:'0'}}>

                    <header>
                         <div className="col l12 section-into  s12">

                                <div className="row">

                                    <div className="col l7 box-picture01 s12"
                                          data-aos="fade-right"
                                          data-aos-duration="1000"
                                    >
                                        <img src={pictures.Vetor01} alt="vetor" className="vetor01  hide-on-small-and-down" />
                                        <div className="cover"></div>
                                          
                                                <img src={pictures.News7} alt="News"/>
    
                                           
                                            
                                    
                                    </div>

                                    <div className="col l6 box-into offset-l5 s12">

                                        <hr className="barra" />

                                        <p className="detalhe-into hide-on-med-and-up"
                                              data-aos="fade-up"
                                              data-aos-duration="1000"
                                        >
                                          
                                               Mantenha-se informado, temos informações úteis para si.
                                           
                                        </p>

                                        <p className="detalhe-into hide-on-small-and-down"
                                              data-aos="fade-up"
                                              data-aos-duration="1000"
                                        >
                                          
                                               Mantenha-se informado, <br /> temos informações úteis <br /> para si.
                                           
                                        </p>

                                        
                                        <div className="box-desc"
                                             data-aos="fade-up"
                                             data-aos-duration="1000"
                                        >

                                       

                                            <p className="detalhe-into-2 hide-on-med-and-up">
                                                Não deixe de acompanhar as nossas diversas
                                                publicações sobre à área em questão, publicações
                                                inerentes como: Discursos, Apresentações e muito mais.
                                            </p>

                                            <p className="detalhe-into-2 hide-on-small-and-down">
                                                Não deixe de acompanhar as nossas diversas<br />
                                                publicações sobre à área em questão, publicações<br />
                                                inerentes como: Discursos, Apresentações e muito mais.
                                            </p>

                                
                                            <img src={pictures.Vetor02} alt="vetor" className="vetor02" />

                                        </div>

                                       


                                    </div>
                                    
                                
                                </div>

                        </div>

                    </header>
                    <div className="col l6 section-right offset-l6 s12"></div>



                    <section>

                        <div className="col l12 m12 s12 section-news white">

                            <div className="row reset box-news">
                                <div className='box-list-filter'>
                                    <button className={type==='document' ? 'btn-filter-selected':''} onClick={()=>setType('document')}>Documentos</button>
                                    <button className={type==='list' ? 'btn-filter-selected':''} onClick={()=>setType('list')}>Listas 2024</button>
                                </div>
                           {type === 'document' && <div> <p className="title-section center" style={{marginBottom:'16px',fontSize:'34px'}}>
                              Documentos Arquivados
                            </p>
                            <p className="describe-section center" style={{marginTop:'16px', marginBottom:'16px'}}>Filtre os documentos por ano, para encontrares rapidamente o que desejas.</p>
                            <div className='box-options-year'>
                                {/*<button className={`btn-year ${year===2019 ? 'btn-selected': null}`} onClick={()=>setYear(2019)}>2019</button>
                                <button className={`btn-year ${year===2020 ? 'btn-selected': null}`} onClick={()=>setYear(2020)}>2020</button>
                                <button className={`btn-year ${year===2021 ? 'btn-selected': null}`} onClick={()=>setYear(2021)}>2021</button>*/}
                                <button className={`btn-year ${year===2022 ? 'btn-selected': null}`} onClick={()=>setYear(2022)}>2022</button>
                                
                            </div>

                                
                           {/* <p className='center'><i className='fa fa-exclamation alert-notice'></i></p>
                             <h2 className='center hide-on-small-and-down'>
                                 estamos sem notícias <br />por enquanto.
                            </h2>

                            <h3 className='center hide-on-med-and-up'>
                                 estamos sem notícias por enquanto.
                            </h3> */}

                          {GetAllPost()} </div>

                           }

                           {type === 'list' && <div> <p className="title-section center" style={{marginBottom:'16px',fontSize:'34px'}}>
                              Lista de Pensionistas para Prova de vida 2024
                            </p>
                            <p className="describe-section center" style={{marginTop:'16px', marginBottom:'16px'}}>Encontre as informações que desejas, consultando os grupos.</p>
                                                           
                           {/* <p className='center'><i className='fa fa-exclamation alert-notice'></i></p>
                             <h2 className='center hide-on-small-and-down'>
                                 estamos sem notícias <br />por enquanto.
                            </h2>

                            <h3 className='center hide-on-med-and-up'>
                                 estamos sem notícias por enquanto.
                            </h3> */}

                          {getList2024()} </div>

                           }


                            


                             <div className="col l12 s12 m12 white" style={{height:'132px'}}> </div>

                            </div>

                        </div>

                    </section>

                </div>

            </React.Fragment>
        )
    }

export default PostPage