const link = [
     {
        Id:9,
        Nome:"MINISTÉRIO DAS FINANÇAS",
        Link:"https://www.minfin.gov.ao/PortalMinfin/"

    },
    {
        Id:4,
        Nome:"BANCO NACIONAL DE ANGOLA",
        Link:"https://www.bna.ao/"

    },
    {
        Id:7,
        Nome:"ADM.GERAL TRIBUTÁRIA - AGT",
        Link:"https://agt.minfin.gov.ao/PortalAGT/"

    },
    {
        Id:8,
        Nome:"ARSEG",
        Link:"https://www.arseg.ao/"

    },

   
   {
        Id:2,
        Nome:"COMISSÃO DE MERCADOS E CAPITAIS",
        Link:"https://www.cmc.gv.ao/"

    },
    {
        Id:11,
        Nome:"MAPTSS",
        Link:"http://www.maptss.gov.ao"

    },

    {
        Id:10,
        Nome:"ASAN",
        Link:"http://www.asan.co.ao"

    },
    /*{
        Id:5,
        Nome:"CATOCA",
        Link:"https://www.catoca.com/"

    },*/
    
    {
        Id:6,
        Nome:"SOGESTER",
        Link:"https://www.sogester.co.ao"

    },
    {
        Id:3,
        Nome:"BANCO SOL",
        Link:"https://www.bancosol.ao/"

    },
    {
        Id:1,
        Nome:"BANCO ANGOLANO DE INVESTIMENTO",
        Link:"https://www.bancobai.ao/"

    }
    
   
   
]

export default link