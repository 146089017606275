import React from 'react'

function Faq(props){


    function OpenClose(){

     
       
        if(props.id === 0){

            props.setId(1)
            props.setActive(props.index)
            
        }else{
           
            props.setId(0)
            props.setActive("")

        }
    

    }

    return(
        <React.Fragment>

            <div className="row box-faq">
                                            
                    <div className="box-q-arrow">
                        <div className="box-quest col l11 s10">
                            <p className="title-question">{ props.title}</p>
                        </div>
                        <div className="box-icon col l1 s2 center">
                            <button className="btn-open-ansewer" 
                            onClick={()=> OpenClose()}>
                                <i className={(  props.active ===  props.index ? "fa-minus fa":"fa-plus fa" )} aria-hidden="true" />
                            </button>
                        </div>
                    </div>

                    <div className={( props.active ===  props.index ? "show" : "") +" col l12 s12 m12 box-anwser "}>

                        {props.children}
                    
                    </div>
                    
                </div>

        </React.Fragment>
    )
}

export default Faq