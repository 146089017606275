
// === 2024

import PR_2 from "../docs/2024/PR_GRUPO_2.pdf"
import PS_2 from "../docs/2024/PS_GRUPO_2.pdf"
import GROUP_3 from "../docs/2024/GRUPO_3.pdf"
import GROUP_4 from "../docs/2024/GRUPO_4.pdf"
import GROUP_5 from "../docs/2024/GRUPO_5.pdf"



const List_2024 = [

    {

        Id:1,
        Titulo: "Lista de PR Grupo II",
        Cover:"./img/list-img-2.jpg",
        Categoria:"LISTA PR - 2024",
        Data:"27 Fevereiro 2024",
        link:PR_2,
       
       
       
    },

   {

       
    Id:2,
    Titulo: "Lista de PS Grupo II",
    Cover:"./img/list-img-2.jpg",
    Categoria:"LISTA PS - 2024",
    Data:"27 Fevereiro 2024",
    link:PS_2,
     
       


    },

    {

        Id:3,
        Titulo: "Lista do Grupo III",
        Cover:"./img/list-img-2.jpg",
        Categoria:"LISTA - 2024",
        Data:"27 Fevereiro 2024",
        link:GROUP_3,
       


    },

    {

        Id:4,
        Titulo: "Lista do Grupo IV",
        Cover:"./img/list-img-2.jpg",
        Categoria:"LISTA - 2024",
        Data:"27 Fevereiro 2024",
        link:GROUP_4,
      


    },

    {

        Id:5,
        Titulo: "Lista do Grupo V",
        Cover:"./img/list-img-2.jpg",
        Categoria:"LISTA - 2024",
        Data:"27 Fevereiro 2024",
        link:GROUP_5,
       


    },
   

]

export default List_2024