import React, { Component } from 'react'
import './sobre.css'
import pictures from '../../pictures'
import AOS from 'aos'
import '../../styles/aos-css/aos.css'
class SobrePage extends Component {

	render() {

		AOS.init()

		return (

			<React.Fragment>

				<div className="row sobre">

					<header>

						<div className="col l12 section-into s12">

							<div className="row">

								<div className="col l7 box-picture01"
									data-aos="fade-right"
									data-aos-duration="1800"
								>
									<img src={pictures.Vetor01} alt="vetor" className="vetor01" />
									<div className="cover"></div>

									<img src={pictures.We} alt="investimento" />




								</div>

								<div className="col l6 box-into offset-l5 s12">

									<hr className="barra" />

									<p className="detalhe-into hide-on-small-and-down"
										data-aos="fade-up"
										data-aos-duration="1000"
									>
										
									Tenha óptimos resultados <br /> trabalhando connosco, tire <br /> suas duvidas acerca de nós conhecendo-nos agora.
														
									</p>

									<p className="detalhe-into hide-on-med-and-up"
										data-aos="fade-up"
										data-aos-duration="1000"
									>
										
									Tire  suas duvidas acerca de nós, conhecendo-nos agora.
														
									</p>


									<div className="box-desc ">



										<p className="detalhe-into-2 hide-on-small-and-down">
											Prevemos o futuro como um desafio, colocando ao <br />
											serviço dos nossos clientes,  todos os nossos<br />
											conhecimentos e experiências, de modo a conceber <br />
											formas efectivas de agir.
										</p>

										<p className="detalhe-into-2 hide-on-med-and-up">
											Prevemos o futuro como um desafio, colocando ao 
											serviço dos nossos clientes,  todos os nossos
											conhecimentos e experiências, de modo a conceber
											formas efectivas de agir.
										</p>


										<img src={pictures.Vetor02} alt="vetor" className="vetor02" />

									</div>




								</div>


							</div>

						</div>

						<div className="col l6 section-left s12">

							<div className="row">

								<div className="col l12"
									data-aos="fade-right"
									data-aos-duration="1000"
								>

									<hr className="barra" style={{ width: '80px' }} />
									<p className="title-section">Não deixe para depois,<br />
										saiba quem somos e como estamos<br /> constituídos perante a lei.
									</p>

								

									<p className="describe-section hide-on-small-and-down">Somos uma sociedade comercial de direito angolano,  criada ao <br />
										abrigo do Decreto n.º 25/98, de 07 de Agosto, por escritura pública de <br />
										01 de Dezembro de 1998, matriculada na conservatória do registo<br />
										comercial sob o n.º 1999-190, contribuinte fiscal nº 5401042434, com <br />
										o registo estatístico n.º 00007894, licenciada com o n.º 1/ARSEG/MF/99 <br />
										para o exercício da actividade de<br />
										gestão de fundos de pensões.
									</p>

									<p className="describe-section hide-on-med-and-up">Somos uma sociedade comercial de direito angolano,  criada ao 
										abrigo do Decreto n.º 25/98, de 07 de Agosto, por escritura pública de 
										01 de Dezembro de 1998, matriculada na conservatória do registo
										comercial sob o n.º 1999-190, contribuinte fiscal nº 5401042434, com 
										o registo estatístico n.º 00007894, licenciada com o n.º 1/ARSEG/MF/99 
										para o exercício da actividade de
										gestão de fundos de pensões.
									</p>

								</div>



							</div>

						</div>

						<div className="col l6 section-right center s12" >

							<img src={pictures.Smile} alt="woman-world" className="woman-world"
								data-aos="zoom-in-up"
								data-aos-duration="1500"
							/>

						</div>

					</header>

					<div className="col l12 s12 m12 reset">

						<section className="section-02">

							<div className="row">

								<div className="col l5 hide-on-small-and-down"
									
								style={{position:'relative'}}>
									<img src={pictures.Team} alt="picture03" className="picture-03" 
										data-aos="fade-right"
										data-aos-duration="1200"
									/>
								
								</div>

								<div className="col l7 box-content"
								
								>
									<hr className="barra" style={{ width: '80px' }} />
									<p className="title-section"
											data-aos="zoom-in-up"
											data-aos-duration="1000"
									>Nossa Identidade <br />
										Corporativa. </p>

									<p className="describe-section"
										data-aos="fade-up"
										data-aos-duration="1000"
									>A nossa identidade corporativa encontra reflexo na nossa declaração
										de missão, visão e os valores que constituem parâmetros interdependentes
										e os componentes chave da formulação de estratégias, da nossa filosofia
										e da cultura empresarial, que pauta a nossa actuação.</p>

									<div className="row">

										<div className="col l3 s12 hide-on-small-and-down"
											data-aos="fade-right"
											data-aos-duration="1000"
										>

											<img src={pictures.Missao} alt="our mission" className="icone-info" />

										</div>

										<div className="col l3 center s12 hide-on-med-and-up"
											data-aos="fade-right"
											data-aos-duration="1000"
										>

											<img src={pictures.Missao} alt="our mission" className="icone-info" />

										</div>

										

										<div className="col l9 s12"
											data-aos="fade-up"
											data-aos-duration="1000"
										>
											<p className="title">Nossa Missão </p>

											<p className="p-desc"
												
											>
											Assegurar de modo eficiente e eficaz, a gestão, utilização e conversão dos<br />
											recursos ou fundos disponibilizados numa carteira diversificada de activos
											visando a criação de mais-valias para os seus clientes, accionistas, <br />
											trabalhadores e colaboradores, mantendo elevados padrões de conduta e <br />
											excelência na sua actuação;
											
											<br /><br />

												Gerar resultados, primando pela ética e legalidade, para<br />
												responder às expectativas dos nossos <br />
												stakeholders.
											</p>
										</div>

									

										

									</div>


								</div>

								

								

								
								

							</div>
						</section>
					</div>

			
					 <div className='col l12 s12 m12 reset'>

						<div className='row'> 

							<div className='col l5' style={{paddingRight:'0'}}>

								<div className="col l3 hide-on-small-and-down"
									data-aos="fade-right"
									data-aos-duration="1500"
								>

									<p>
										<img src={pictures.Valor} alt="our mission" className="icone-info" style={{ width: '120px' }} />
									</p>
								</div>

								<div className="col l3 center s12 hide-on-med-and-up"
									data-aos="fade-right"
									data-aos-duration="1500"
								>

									<p>
										<img src={pictures.Valor} alt="our mission" className="icone-info" style={{ width: '120px' }} />
									</p>
								</div>

								<div className="col l9"
									data-aos="fade-up"
									data-aos-duration="1500"
								>
									<p className="title" style={{ marginTop: '40px' }}>Nossos Valores </p>

									<p className="p-desc">
									Sermos referência na indústria de pensões é de todo 
									justificável que, os fundos sobre a nossa gestão sejam geridos 
									por profissionais e especialistas altamente qualificados 
									cuja conduta paute pela excelência e pelos seguintes  
									Valores: <br /><br />

									<b>Valores relacionados com a Empresa</b> <br />
									<ul>
										<li>Ética e Rigor;</li>
										<li>Profissionalismo;</li>
										<li>Transparencia – Prestação de Contas;</li>
										
									</ul>

									<b>Valores Relacionados com os Clientes:</b> <br />
									<ul>
										
										<li>Visão de longo prazo; </li>
										<li>Parceiria;</li>
										<li>Oferta do preço justo.</li>
									</ul>

									<b>Valores Internos entre os Trabalhadores: </b> <br />
									<ul>
										<li>Espirito de equipa e entreajuda;  </li>
										<li>profissionalismo e expansão de conhecimentos;</li>
										<li>comprometimento e lealdade;</li>
										
									</ul>
									
									 
								

									


								




									</p>
								</div>


							</div>

							<div className='col l7 '>

								<div className="col l3 center hide-on-small-and-down" data-aos="fade-right"data-aos-duration="1500">
									
									<p>
										<img src={pictures.Visao} alt="our mission" className="icone-info" style={{ width: '90px', marginTop: '24px' }} />
									</p>
								</div>
								<div className="col l3 s12 center hide-on-med-and-up" data-aos="fade-right"data-aos-duration="1500">
									
									<p>
										<img src={pictures.Visao} alt="our mission" className="icone-info" style={{ width: '90px', marginTop: '24px' }} />
									</p>
								</div>

								<div className="col l9"	data-aos="fade-up"data-aos-duration="1000">
								
									<p className="title" style={{ marginTop: '48px' }}>Nossa Visão </p>

									<p className="p-desc">
										Firmar-se cada vez mais como instituição de referência na indústria de 
										fundos de pensões, sustentando a sua posição de primeira sociedade 
										de fundos de pensões em Angola, com total respeito pelos interesses 
										dos demais concorrentes no mercado nacional de previdência privada;<br /><br />

										Actuar no mercado com melhores serviços e produtos de modo a criar
										valor e proporcionar maior satisfação aos seus clientes;
										<br /><br />

										Adoptar as melhores práticas nas áreas em que actua, com profissionais
										devidamente qualificados, que assegurem as suas responsabilidades
										de acordo com critérios de elevada ética e excelência profissional;

										<br /><br />

										Ser o exemplo em matéria de gestão de reforma complementar,
										transmitindo aos associados e beneficiários dos fundos sob sua gestão, a
										confiança numa relação que se pretende duradoura.

										<br /><br />

										Contribuir de forma aprofundada e pró-activa para o desenvolvimento da indústria de pensões em Angola, 
										praticando todos os actos e operações permitidas por lei visando a administração e 
										gestão de fundos de que seja gestora e de outros nichos de negócios.



									</p>
								</div>

								

							</div>
							

						</div>

					</div>

					<div className='col l12 s12 m12 white card-onganigrama center reset'>

						<div>
							<hr className="barra" style={{ width: '80px', margin:'auto' }} />
							<p className="title-section"
								data-aos="zoom-in-up"
								data-aos-duration="1000"
							>Saiba como estamos <br /> organizado pelo nosso Organograma 

							</p>
								
						</div>

						<div>
							<img src={pictures.Organograma} alt="Organograma" title="Organograma Gestão de Fundos" className='organograma' 
								 data-aos="fade-left"
								 data-aos-easing="linear"
								 data-aos-duration="1500"
							/>
						</div>

					</div>

						

				</div>

			</React.Fragment>
		)
	}
}

export default SobrePage