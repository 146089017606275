import React from "react";
import './rodape.css'
import Links from '../../linkUteis'
import {Link} from 'react-router-dom'
import {animateScroll as scroll} from 'react-scroll'

function Rodape(){

    const ano = new Date();

   function GetLinksUteis(){

        return(

        Links.map((link)=>{

            return(
                <div key={link.Id}>
                <li><i className="fa fa-circle" aria-hidden="true" /><a href={link.Link} target="blank" >{link.Nome}</a></li>
                </div>
            )

        })

        )
    }

        return( 
            <React.Fragment>
               <footer className="page-footer">
                    <div className="containerMy">
                        <div className="row" style={{margin:'0'}}>
                            <div className="col l2 s6">
                               
                               <img src="img/logowhite.png" alt="Gestão de Fundos" className="logowhite" />
                                
                            </div>
                            <div className="col l5 box-1 s12">
                                <div className="box-title-barra">
                                    <h5 className="white-text title-footer">Mapa do site</h5>
                                    <hr className="barra-2"/>
                                </div>
                               
                               <div className="foonter-map">

                                    <ul className="links">
                                        <li onClick={()=>{scroll.scrollToTop()}}><i className="fa fa-chevron-right" aria-hidden="true" /><Link to="/">HOME</Link></li>
                                        <li onClick={()=>{scroll.scrollToTop()}}><i className="fa fa-chevron-right" aria-hidden="true" /><Link to="/servico">SERVIÇOS</Link></li>
                                        <li onClick={()=>{scroll.scrollToTop()}}><i className="fa fa-chevron-right" aria-hidden="true" /><Link to="/contacto">CONTACTO</Link></li>
                                    
                                    </ul>

                                    <ul className="links">
                                   
                                        <li onClick={()=>{scroll.scrollToTop()}}><i className="fa fa-chevron-right" aria-hidden="true" /><Link to="/sobre">SOBRE</Link></li>
                                        <li onClick={()=>{scroll.scrollToTop()}}><i className="fa fa-chevron-right" aria-hidden="true" /><Link to="/fundos_sobre_gestao">FUNDOS SOB GESTÃO</Link></li>
                                        <li onClick={()=>{scroll.scrollToTop()}}><i className="fa fa-chevron-right" aria-hidden="true" /><Link to="/posts">PUBLICAÇÕES</Link></li> 
                                 </ul>

                                </div>
                            </div>
                           
                            <div className="col l4 box-1">
                                <div className="box-title-barra">
                                    <h5 className="white-text title-footer">Links Utéis</h5>
                                    <hr className="barra-2"/>
                                </div>
                               
                                <ul className="links">
                                    {GetLinksUteis()}
                                </ul>
                            </div>
                            {/*<div className="col l3 box-1">
                                <div className="box-title-barra">
                                    <h5 className="white-text title-footer">Mídia Sociais</h5>
                                    <hr className="barra-2"/>
                                </div>
                               
                               <p className="link-midia">
                                   <a href="#d"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                   <a href="#d"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                                   <a href="https://twitter.com"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                                   <a href="https://instagram.com"><i className="fa fa-instagram" aria-hidden="true"></i></a>
                               </p>
                               
                            </div>*/}
                        </div>
                    </div>
                    <div className="copyright">
                        <div className="center">
                            <p> ©{ano.getFullYear()} All Rights Reserved Gestão de Fundos <br />Desenvolvido por <a href="https://www.culysoft.com" target="blank" className="red-text">Culysoft</a></p>
                
                        </div>
                    </div>
                </footer>

            </React.Fragment>
        )
    
}

export default Rodape