import React,{useState,useEffect} from 'react' 
import './contacto.css'
import pictures from '../../pictures'
import AOS from 'aos'
import '../../styles/aos-css/aos.css'

import END_POINT from './enpoint'

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import axios from "axios"

const validateSendEmail = yup.object().shape({
    Nome: yup.string().required("Campo Obrigatório").max(35),
    Assunto: yup.string().required("Campo Obrigatório").max(50),
    Mensagem: yup.string().required("Campo Obrigatório").max(350),
    Email: yup.string().required("Campo Obrigatório").max(60)
})


function Contacto(){


    const { register, handleSubmit, formState: { errors } } = useForm({

        resolver: yupResolver(validateSendEmail)
    })

    const [formStatu, setFormStatu] = useState(false)
    const [waiting, setWaiting] = useState(false)

    const sendEmail = (data) => {


        setWaiting(true)

        axios.post(END_POINT, data)
            .then((response) => {
                alert('EMAIL ENVIADO COM SUCESSO, OBRIGADO ! ')
                setFormStatu(true)
                setWaiting(false)

            })
            .catch((response) => {

                alert("ERRO AO ENVIAR, TENTE MAIS TARDE")
                setWaiting(false)

            })

    }

    useEffect(()=>{
        if (formStatu) {

            document.querySelector('.resetf').click()
            setInterval(() => { setFormStatu(false) }, 2000)
        }
    })

    AOS.init()

        return(

            <React.Fragment>

                <div className="row contacto" style={{marginBottom:'0'}}>

                    <header>
                         <div className="col l12 section-into s12 m12">

                                <div className="row">

                                    <div className="col l7 box-picture01"
                                          data-aos="fade-right"
                                          data-aos-duration="1000"
                                    >
                                        <img src={pictures.Vetor01} alt="vetor" className="vetor01 hide-on-small-and-down" />
                                        <div className="cover"></div>
                                          
                                                <img src={pictures.Contacto} alt="News"/>
    
                                           
                                            
                                    
                                    </div>

                                    <div className="col l6 box-into offset-l5 s12">

                                        <hr className="barra" />

                                        <p className="detalhe-into hide-on-small-and-down"
                                              data-aos="fade-up"
                                              data-aos-duration="1000"
                                        >
                                          
                                          Necessitando dos nossos <br />
                                          serviços ? não hesite entre <br />
                                          em contacto, temos soluções <br />
                                          para o seu problema.

                                          
                                                                                
                                        </p>

                                        <p className="detalhe-into hide-on-med-and-up"
                                              data-aos="fade-up"
                                              data-aos-duration="1000"
                                        >
                                          
                                          Necessitando dos nossos
                                          serviços ? <br />não hesite entre
                                          em contacto.

                                          
                                                                                
                                        </p>

                                        
                                        <div className="box-desc"
                                             data-aos="fade-up"
                                             data-aos-duration="1000"
                                        >

                                       

                                            <p className="detalhe-into-2 hide-on-small-and-down">
                                                Estamos preparado com profissionais e soluções<br />
                                                adequadas para suprimir os seus problemas.
                                            </p>

                                            <p className="detalhe-into-2 hide-on-med-and-up">
                                                Estamos preparado com profissionais e soluções
                                                adequadas para suprimir os seus problemas.
                                            </p>

                                
                                            <img src={pictures.Vetor02} alt="vetor" className="vetor02" />

                                        </div>

                                       


                                    </div>
                                    
                                
                                </div>

                        </div>

                    </header>
                    <div className="col l6 section-right offset-l6 s12"></div>



                    <section>

                        <div className="col l12 m12 s12 section-contacto white">

                            <div className="row reset box-contacto">


                                <hr className="barra" style={{width:'150px', margin:'auto'}}/>
                                <p className="title-section center hide-on-small-and-down"
                                     data-aos="zoom-in-up"
                                     data-aos-duration="1000"
                                >
                                    Querendo contactar-nos ? <br />
                                    Deixe a sua mensagem ou liga-nos
                                </p>

                                <p className="title-section center hide-on-med-and-up"
                                     data-aos="zoom-in-up"
                                     data-aos-duration="1000"
                                >
                                    
                                    Deixe a sua mensagem ou liga-nos
                                </p>

                            <div className="row">

                                     <form className="col l6 s12 m8 offset-l3 padding-zero"
                                          data-aos="fade-up"
                                          data-aos-duration="1000"
                                          onSubmit={handleSubmit(sendEmail)}
                                          method='POST'
                                    >

                                        <div className="row">

                                            <div className="col l12 s12 padding-zero">
                                                <div className="input-field">

                                                    <input type="text"  name="Nome" placeholder="Nome" 
                                                   required
                                                    className="input-name"
                                                    {...register("Nome")}
                                                    value={errors.Nome?.message}
                                                    maxLength={35}
                                                    />

                                                </div>
                                            </div>
                                            
                                            <div className="col l6 s12 padding-zero">
                                                <div className="input-field">

                                                    <input type="email"  name="Email" 
                                                    placeholder="Email"
                                                    required 
                                                    
                                                     {...register("Email")}
                                                     maxLength={60}
                                                     />

                                                </div>
                                            </div>

                                            <div className="col l6 s12 padding-zero">
                                                <div className="input-field">

                                                    <input type="text"  name="Assunto" placeholder="Assunto" 
                                                    required 
                                                    {...register("Assunto")}
                                                    maxLength={50}
                                                    />

                                                </div>
                                            </div>

                                          

                                            <div className="col l12 s12 padding-zero">

                                                <textarea placeholder="Mensagem" name="Mensagem"
                                                required
                                                {...register("Mensagem")}
                                                maxLength={350}
                                                ></textarea>

                                            </div>
                                            <div className="col l12 s12 m12"></div>
                                            <div className="col l6 s12 hide-on-small-and-down">
                                                <button type="submit" className="btn-link-service btn-send">
                                                   
                                                {!waiting ? "ENVIAR" : <i className="fa fa-circle-o-notch fa-spin fa-lg fa-fw" />}
                                                
                                                </button>
                                               
                                            </div>

                                            <div className="col l6 s12 center hide-on-med-and-up">
                                                <button type="submit" className="btn-link-service btn-send">
                                                   
                                                {!waiting ? "ENVIAR" : <i className="fa fa-circle-o-notch fa-spin fa-lg fa-fw" />}
                                                
                                                </button>
                                               
                                            </div>

                                            <button type="reset" className="resetf" hidden>reset</button>

                                        </div>

                                    </form>

                                    <div className="col l12 box-adress s12">
                                        <hr className="barra" style={{width:'80px', margin:'auto'}}/>
                                        <p className="title-section center"
                                             data-aos="zoom-in-up"
                                             data-aos-duration="1000"
                                        >
                                            Conheça onde estamos localizado <br /> e venha visitar-nos
                                        </p>

                                    

                                    </div>

                                   
                                    

                            </div>

                               


                                        


                            

                            </div>

                        </div>

                    </section>

                    <div className="col l12 s12 m12 section-008">

                            <section className="section-08" id="CONTACTO">

                                <div className="row">

                                    <div className="col l5 box-endereco s12"
                                          data-aos="fade-right"
                                          data-aos-duration="2000"
                                    >

                                        <div className="box-det">
                                            <p className="title-section-2"><i className="fa fa-map-marker fa-lg" aria-hidden="true" style={{color:'#C51F29'}} />
                                            Nossa Localização
                                            </p>
                                            <p className="detalhe-en">Rua Quenguela-Norte, Residência S/N <br />
                                                Bairro Cabolombo/Quifica, <br />
                                                Destrito Urbano do Talatona - Luanda</p>


                                        </div>

                                        <div className="box-det">
                                            <p className="title-section-2"><i className="fa fa-phone fa-lg" aria-hidden="true" style={{color:'#C51F29'}} />Nosso Telefone</p>
                                            <p className="detalhe-en">+244 937 028 555</p>


                                        </div>

                                        <div className="box-det">
                                            <p className="title-section-2"><i className="fa fa-envelope " aria-hidden="true" style={{color:'#C51F29'}} />Nosso Correio Electronico</p>
                                            <p  className="detalhe-en">geral@gestaodefundos.co.ao</p>


                                        </div>

                                    </div>

                                    <div className="col l12 s12" style={{padding:'0'}}>

                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3314.284268043993!2d13.174939139023238!3d-8.937970983732935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1a51f54d1876874d%3A0x64dc6832fc27fb95!2sRua%20Quenguela%20Norte%2C%20Belas!5e0!3m2!1spt-PT!2sao!4v1638242737526!5m2!1spt-PT!2sao" allowFullScreen loading="lazy" 
                                          data-aos="fade-left"
                                          data-aos-duration="1000"
                                          title="Endereço Gestão de Fundos"
                                    />


                                    </div>


                                    

                                </div>

                            </section>

                        </div>

                        <div className="col l12 s12 m12 white" style={{height:'132px'}}></div>


                </div>

            </React.Fragment>
        )
   
}

export default Contacto