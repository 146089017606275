import React,{ useState, useEffect } from 'react'
import pictures from '../../pictures'
//import parceiros from '../../parceiros'
import clientes from '../../clientes'
import AOS from 'aos'
import '../../styles/aos-css/aos.css'
import './home.css'
import Services from '../../arrayServices'
import Slider from "react-slick"
import "../../styles/slick/slick.css"; 
import "../../styles/slick/slick-theme.css"; 
import {animateScroll as scroll} from 'react-scroll'
import Faq from '../../components/faq/faq'
import {Link} from 'react-router-dom'
import postAPI from '../../services/postsAPI'










function Home(){


    const [active, setActive] = useState("")
    const [id, setId] = useState(0) 
    const [preloader, setPreloader] = useState(true)


    useEffect(()=>{

        setTimeout(()=>{

            setPreloader(false)

        },1000)

    },[])


    function GetAllPost(){


        return (

        postAPI.map(posts=>{

            return(
                <div className="col l6 card-post s12 offset-l3"
                     data-aos="fade-up"
                     data-aos-duration="1800"
                     key={posts.Id}
                 >

                        <div className="limite">

                            <div className="cat-post">
                                    <p>{posts.Categoria}</p>
                            </div>

                            <div className="cover-post">
                                <img src={posts.Cover} alt="post-cover" className="post-cover"/>
                                
                            </div>
                            <div className="det-post">
                                <p><i className="fa fa-calendar" style={{marginRight:'16px'}}></i>{posts.Data}</p>
                                
                                <p className="title-post">
                                        <Link to="/posts"  rel="noopener noreferrer" onClick={()=>{scroll.scrollToTop()}}>
                                            {posts.Titulo}
                                            </Link>
                                        </p>
                

                                    {/*<Link to="/posts" className='link-read'  rel="noopener noreferrer" onClick={()=>{scroll.scrollToTop()}}>
                                        Ver todos ...
                                    </Link> */}

                                   
                                      {/*  <p className="title-post">
                                        
                                        {posts.Titulo}
                                            
                                      </p> */}
                                   
                               
                                
                            </div>
                        
                        </div>

                 </div>
            )
        })

        )
    }


    

   

   function CardService(){

        return(

            Services.map((services)=>{
                return(
                    <div className="col l4 card-box s12" key={services.Id} 
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    >
                    <Link to="servico">
                    <div className="card-service" onClick={()=>{scroll.scrollToTop()}}>
                        <p className="center">
                            <img src={services.Icon} alt="our service" className="icon-service" />
                        </p>
                        <p className="title-service center title">{services.Title}</p>
                       
                    </div>
                    </Link>
                </div>
                )
            })
            
        )

    }

   


   /* function GetParceiros(){

        return(
            parceiros.map((parceiro)=>{

                return(
                    <div className="col l3 card-parceiro s12" key={parceiro.Id}>
                        <a href={parceiro.Link} target="blank">
                        <div className="box-parceiro">
                        <img src={parceiro.Logo} alt={parceiro.Nome} className="img-parceiro" title={parceiro.Nome} />
                        </div>
                        </a>
                    </div>
                )

            })
        )
    }*/

 
    function GetClientes(){

            return(
                clientes.map((cliente)=>{

                    return(
                        <div className="col l3 card-parceiro s12" key={cliente.Id}>

                            <a href={cliente.Link} target="blank">
                            <div className="box-parceiro">
                            <img src={cliente.Logo} alt={cliente.Nome} className="img-parceiro img-c" title={cliente.Nome} />
                            </div>
                            </a>
                        </div>
                    )

                })
            )
        }


          
         AOS.init()

            const settings ={

                dots:true,
                infinite:true,
                speed: 2500,
                slidesToShow:4,
                slidesToScrooll:4,
                autoplay:true,
                autoplaySpeed:1000,
                pauseOnHover:false
            }

            const settingsMobile ={

                dots:true,
                infinite:true,
                speed: 2500,
                slidesToShow:1,
                slidesToScrooll:1,
                autoplay:true,
                autoplaySpeed:1000,
                pauseOnHover:false
            }

           /* const TestemunhoSlide ={

                dots:true,
                infinite:true,
                speed: 5000,
                slidesToShow:1,
                slidesToScrooll:2,
                autoplay:true,
                autoplaySpeed:3000,
            } */

            const InitSlide ={

                dots:false,
                infinite:true,
                speed: 5000,
                slidesToShow:1,
                slidesToScrooll:2,
                autoplay:true,
                autoplaySpeed:4000,
            }
          
          
           
          
            return(
               
                <React.Fragment>

                    <div className={preloader ? 'preloader':'preloader exitPreloader'}>
                        <img src={pictures.Preloader} alt="preloader-animation" className="img-preloader" />
                    </div>

                    <div className="row" style={{position:'relative', marginBottom:'0'}}>
                        <a href="https://wa.me/+244937028555?text=Saudações Gestão de Fundos." target="_blank" rel="noreferrer">
                            <button className="btn-up" title="Estamos activo no Whatsapp">
                                <i className="fa fa-whatsapp fa-2x"></i>
                            </button>
                        </a>

                       

                        <header>

                            <div className="col l12 section-into s12 m12">

                                <div className="row">

                                    <div className="col l7 box-picture01 s12"
                                          data-aos="fade-right"
                                          data-aos-duration="1800"
                                    >
                                        <img src={pictures.Vetor01} alt="vetor" className="vetor01" />
                                        <div className="cover"></div>
                                            <Slider {...InitSlide} >
                                                <img src={pictures.Picture01} alt="investimento" className="img-carrousel" />
                                                <img src={pictures.Picture04} alt="investimento" className="img-carrousel" />
                                                <img src={pictures.News5} alt="Fundo de Pensões" className="img-carrousel" />
                                            </Slider>
                                            
                                    
                                    </div>

                                    <div className="col l6 box-into offset-l5 s12">

                                        <hr className="barra" />

                                        <p className="detalhe-into hide-on-med-and-up">
                                           Enxergamos o futuro <br />como um desafio, <br /> colocando ao  serviço dos nossos <br /> clientes.
                                                
                                           
                                        </p>

                                        <p className="detalhe-into hide-on-small-and-down">
                                          Enxergamos o futuro como <br /> um desafio, colocando ao <br /> serviço dos nossos <br /> clientes .
             
                                         </p>        
                                                
                                            
                                
                                            
                                           
                                       

                                        
                                        <div className="box-desc">

                                       

                                            <p className="detalhe-into-2 hide-on-med-and-up">
                                                Asseguramos de modo eficiente e eficaz, a gestão,
                                                utilização e conversão dos recursos  disponibilizados
                                                numa carteira diversificada de activos.
                                            </p>

                                            <p className="detalhe-into-2 hide-on-small-and-down">
                                                Asseguramos de modo eficiente e eficaz, a gestão, <br />
                                                utilização e conversão dos recursos  disponibilizados<br />
                                                numa carteira diversificada de activos.
                                            </p>

                                            <Link to="servico">
                                                <button className="btn-link-service" onClick={()=>{scroll.scrollToTop()}}>NOSSOS SERVIÇOS</button>
                                            </Link>
                                            <img src={pictures.Vetor02} alt="vetor" className="vetor02 hide-on-small-and-down" />

                                        </div>

                                       


                                    </div>
                                    
                                
                                </div>

                            </div>

                            <div className="col l6 section-left">

                                <div className="row">

                                    <div className="col l12"  
                                        data-aos="fade-right"
                                        data-aos-duration="1200"
                                    >

                                        <hr className="barra" style={{width:'80px'}}/>
                                        <p className="title-section">Conheça a Nossa Identidade<br />
                                         Corporativa.</p>

                                         <p className="describe-section">A nossa identidade corporativa encontra reflexo na nossa declaração<br />
                                            de missão, visão e os valores que constituem parâmetros interdependentes<br />
                                            e os componentes chave da formulação de estratégias, da nossa filosofia<br />
                                            e da cultura empresarial, que pauta a nossa actuação.</p>

                                    </div>

                                    <div className="col l12"
                                         data-aos="fade-up"
                                         data-aos-duration="1000"
                                    >

                                        <div className="row">

                                            <div className="col l3 s6 offset-s3">

                                                <img src={pictures.Missao} alt="our mission" className="icone-info" />

                                            </div>
                                            <div className="col l9 s12">
                                             <p className="title">Nossa Missão </p>                                          
                                                
                                                <p className="p-desc">
                                                   Gerar resultados, primando pela ética e legalidade, para
                                                    responder às expectativas dos nossos
                                                    stakeholders.
                                                </p>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col l12"
                                         data-aos="fade-up"
                                         data-aos-duration="1000"
                                    >

                                        <div className="row">

                                            <div className="col l3 s6 offset-s3">

                                                <img src={pictures.Valor} alt="our value" className="icone-info" />

                                            </div>
                                            <div className="col l9 s12">
                                                <p className="title">Nossos Valores </p>
                                                    
                                               
                                                <p className="p-desc">
                                                   Sermos referência na indústria de pensões, os fundos sob
                                                    a nossa gestão serem geridos por profissionais e 
                                                    especialistas altamente qualificados.
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                
                                </div>

                            </div>

                            <div className="col l6 section-right s12 center" >

                                    <img src={pictures.Picture02} alt="woman-world" className="woman-world" 
                                        data-aos="zoom-in-up"
                                        data-aos-easing="linear"
                                        data-aos-duration="1500"
                                     />
                                       
                            </div>

                        </header>

                        <div className="col l12 s12 m12 reset">

                            <section className="section-02">

                                <div className="row">

                                    <div className="col l6 s12"
                                     data-aos="fade-right"
                                     data-aos-duration="1200"
                                    >
                                        <img src={pictures.Picture03} alt="picture03" className="picture-03" />
                                    </div>

                                    <div className="col l6 box-content s12"
                                        data-aos="fade-left"
                                        data-aos-duration="1500"
                                    >
                                        <hr className="barra" style={{width:'80px'}}/>
                                        <p className="title-section hide-on-med-and-up">Conheça-nos <br />agora e tenha
                                            uma <br />óptima experiência
                                            trabalhando connosco. 
                                        </p>

                                        <p className="title-section hide-on-small-and-down">Conheça-nos agora e tenha <br />
                                            uma óptima experiência  <br />
                                            trabalhando connosco. 
                                        </p>
                                        
                                        <p className="describe-section">Somos uma sociedade comercial de direito angolano,  criada ao abrigo do <br /> 
                                        Decreto n.º 25/98, de 07 de Agosto, por escritura pública de 01 de Dezembro <br />
                                        de 1998, matriculada na conservatória do registo comercial sob o n.º <br />
                                        1999-190...</p>

                                        <Link to="/sobre">
                                            <button className="btn-link-service bs" style={{marginTop:'8px'}}
                                            
                                            onClick={()=>{scroll.scrollToTop()}}>SABER MAIS</button>
                                        </Link>  
                                    </div>

                                </div>
                            </section>
                        </div>

                        <div className="col l12 m12 s12 reset white section-003" style={{marginTop:'88px',paddingBottom:'40px'}}>

                            <section className="section-03" id="SERVICO">

                                <div className="row">

                                    <div className="col l12 s12 m12 center"
                                          data-aos="zoom-in-up"
                                          data-aos-duration="1500"
                                    >

                                        <hr className="barra" style={{width:'80px', margin:'auto'}}/>

                                        <p className="title-section" style={{marginBottom:'2px'}}>
                                            Saiba o que nós exercemos<br /> e fazemos para ajudar-te
                                        </p>

                                        <p className="describe-section" style={{marginBottom:'64px'}}>É o que fazemos hoje, que nos define amanhã, o nosso objecto social <br />
                                            assente em três vectores.</p>

                                    </div>

                                    <div className="col l12 s12 m12">

                                        <div className="row">

                                            {CardService()}

                                                                                    
                                        </div>

                                    </div>

                                </div>
                            
                            </section>

                        </div>

                       {/* <div className="col l12 s12 m12  section-004">
                            <section className="section-04">
                               <div className="row">

                                   <div className="col l12 s12 m12">

                                       <p className="title-section-2 center"> Nossos Parceiros   </p>
                                       <p className="describe-section-2 center hide-on-small-and-down"> Nós e os nossos parceiros olhamos para o futuro com confiança e com um objectivo<br />
                                            comum de maximizar a qualidade da gestão dos fundos que nos estão confiados <br />
                                            adoptando soluções mais rentáveis e adequadas<br />
                                            aos nossos Clientes. 
                                        </p>

                                        <p className="describe-section-2 center hide-on-med-and-up"> Nós e os nossos parceiros olhamos para o futuro com confiança e com um objectivo
                                            comum de maximizar a qualidade da gestão dos fundos que nos estão confiados
                                            adoptando soluções mais rentáveis e adequadas<br />
                                            aos nossos Clientes. 
                                        </p>

                                   </div>

                                    <div className="hide-on-small-and-down">
                                    

                                         {GetParceiros()}
                                
                                    </div>

                                 <Slider {...settingsMobile} className="hide-on-med-and-up">

                                    {GetParceiros()}
                                
                                    </Slider>

                               </div>
                            </section>

            </div> */}

                        <div className="col l12 s12 m12 white reset">

                            <section className="section-05" id="FAQ">
                             

                                <div className="row">

                                    <div className="col l12 s12 m12"
                                          data-aos="zoom-in-up"
                                          data-aos-duration="1500"
                                          style={{marginBottom:'16px'}}
                                    >
                                         <hr className="barra" style={{width:'80px', margin:'auto'}}/>
                                        <p className="center title-section" style={{marginBottom:'2px'}}>
                                            Questões frequentemente postas<br /> sobre a área em questão
                                        </p>
                                        <p className="center describe-section">
                                        Conheça as respostas sobre as diversas questões frequentemente postas<br />
                                        sobre a área de fundo de pensões, tire as tuas duvidas agora.
                                        </p>
                                    </div>

                                    

                                    <div className="col l5 offset-l1 s12 section-faq-1" style={{paddingLeft:'0'}}

                                        data-aos="fade-right"
                                        data-aos-duration="1500"
                                    
                                    >

                                        <Faq title="[ 1 ] Como funciona um plano de pensões ?" active={active} setActive={setActive} index="FAQ1" id={id} setId={setId} >

                                                <p className="ansewer">O plano de pensões funciona da seguinte forma : <br /><i></i>
                                                    <span>- Plano Modelo;</span><br />
                                                    <span>- Associada;</span><br />
                                                    <span>- Participante;</span><br />
                                                    <span>- Sociedade Gestora.</span>

                                                </p>
                                        </Faq>
                            

                                       
                                        <Faq title="[ 2 ] O que é um Fundo de Pensões Aberto ?" active={active} setActive={setActive} index="FAQ2" id={id} setId={setId}>
                                            <p className="ansewer">
                                                    O Fundo de Pensões Aberto são instrumentos financeiros
                                                    que constituem um modo eficiente de planear a reforma,
                                                    estando aptos a financiar Planos de Pensões Particulares
                                                    ou de Empresas, sem necessidade de existência de qualquer
                                                    vínculo entre as diversas partes aderentes.
                                                   

                                             </p>
                                        </Faq>

                                        <Faq title="[ 3 ] Qual é a diferença entre um Fundo de Pensões e uma Previdência Privada ?" active={active} setActive={setActive} index="FAQ3" id={id} setId={setId}>
                                            <p className="ansewer">
                                                    A diferença está no Fundo de Pensão ser um investimento fechado para
                                                    determinada empresa, enquanto a Prevedência Privada é aberta para
                                                    investidores que desejam adquirir um plano.
                                               

                                                </p>
                                        </Faq>
                                        
                                        
                                           
                                        
                                    </div>

                                    
                                    <div className="col l5 section-faq-1 s12" style={{paddingRight:'0'}}
                                         data-aos="fade-left"
                                         data-aos-duration="1500"
                                    >


                                         <Faq title="[ 4 ] O que é um Fundo de Pensões Fechado ?" active={active} setActive={setActive} index="FAQ4" id={id} setId={setId}>
                                            <p className="ansewer">
                                                    Considera-se um Fundo de Pensões Fechado quando promovido
                                                    por um ou mais Associados, desde que entre estes exista 
                                                    um vínculo comum de natureza empresarial,
                                                    associativo, profissional ou social e seja necessário o seu consentimento
                                                    para a inclusão de novos associados, podendo financiar Planos de Pensões de 
                                                    qualquer natureza.
                                                   

                                                </p>
                                        </Faq>
                                         <Faq title="[ 5 ] O que é um Fundo de Pensões ?" active={active} setActive={setActive} index="FAQ5" id={id} setId={setId}>
                                                <p className="ansewer">
                                                  Um Fundo de Pensões é um património autónomo que se destina exclusivamente ao 
                                                  financiamento de um ou mais planos de pensões.
                                                   

                                                </p>
                                        </Faq>
                                        <Faq title="[ 6 ] Quais são os benefícios de um Fundo de Pensões ?" active={active} setActive={setActive} index="FAQ6" id={id} setId={setId}>

                                                <p className="ansewer">
                                                   Os benefícios de um Fundo de Pensões são: <i></i>
                                                   <span style={{marginLeft:'0'}}>a)  Pensão de Pré-reforma</span> <br />
                                                   - Idade igual a 50 anos para Mulheres e 55 anos para homen.s<br />
                                                   <span  style={{marginLeft:'0'}}>b)  Pensão de Reforma</span> <br />
                                                   - Idade igual a 60 anos. <br />
                                                   <span  style={{marginLeft:'0'}}>c)  Pensão de Sobrevivência</span> <br />
                                                   - Quando ao falecimento do cônjuge.
                                                   

                                                </p>
                                        </Faq>
                                        
                                      
                                           
                                        
                                    </div>

                                   
                                
                                </div>
                            
                             </section>

                           


                        </div>


                        <div className="col l12 s12 m12  section-004">
                            <section className="section-04">
                               <div className="row">

                                   <div className="col l12 s12 m12">

                                       <p className="title-section-2 center"> Nossos Clientes</p>
                                       <p className="describe-section-2 center"> Partilhamos um conjunto de valores que orientam o exercício da nossa<br />
                                        actividade e que influenciam a forma como trabalhamos em equipa<br />
                                        e interagimos com os nossos clientes.
                                        </p>

                                   </div>

                                   <Slider {...settings} className="hide-on-small-and-down">

                                        {GetClientes()}

                                    </Slider>

                                     <Slider {...settingsMobile} className="hide-on-med-and-up">

                                        {GetClientes()}

                                    </Slider>

                               </div>
                            </section>

                        </div>


                       {/* <div class="col l12 s12 m12 section-006">

                            <section className="section-06">

                               <div className="row">

                                    <div className="col l12 s12 m12"
                                          data-aos="zoom-in-up"
                                          data-aos-duration="1500"
                                    >
                                         <hr className="barra" style={{width:'80px', margin:'auto'}}/>
                                        <p className="center title-section" style={{marginBottom:'2px'}}>
                                            Precisando dos nossos <br />
                                            serviços ? entre em contacto
                                        </p>
                                        <p className="center describe-section">
                                         Estamos preparado para auxiliar-te, em caso de duvidas entre em<br />
                                         contacto  preenchendo o formulário corretamente ou ligue.
                                        </p>
                                    </div>

                                    <form className="col l6 s12 m8 offset-l3"
                                          data-aos="fade-up"
                                          data-aos-duration="1000"
                                    >

                                        <div className="row">

                                            <div className="col l12">
                                                <div className="input-field">

                                                    <input type="text"  name="Nome" placeholder="Nome" required="true" className="input-name"/>

                                                </div>
                                            </div>
                                            
                                            <div className="col l6">
                                                <div className="input-field">

                                                    <input type="email"  name="Email" placeholder="Email" required="true" />

                                                </div>
                                            </div>

                                            <div className="col l6">
                                                <div className="input-field">

                                                    <input type="text"  name="Assunto" placeholder="Assunto" required="true" />

                                                </div>
                                            </div>

                                          

                                            <div className="col l12">

                                                <textarea placeholder="Mensagem"></textarea>

                                            </div>
                                            <div className="col l12 s12 m12"></div>
                                            <div className="col l6">
                                                <button type="submit" className="btn-link-service btn-send">ENVIAR</button>
                                            </div>

                                        </div>

                                    </form>

                               </div>

                            </section>

                        </div> */}

                        <div className="col l12 s12 m12 white section-009">

                            <section className="section-09 white">

                                <div className="row">

                                  

                                        <div className="col l12 s12 m12"
                                             data-aos="zoom-in-up"
                                             data-aos-duration="1500"
                                        >
                                            <hr className="barra" style={{width:'80px', margin:'auto'}}/>
                                            <p className="center title-section hide-on-small-and-down" style={{marginBottom:'2px'}}>
                                            Informações uteis para <br />
                                            manteres-te informado, acompanhe aqui
                                            </p>

                                            {/*=========----MOBILE ---======== */}
                                            <p className="center title-section hide-on-med-and-up" style={{marginBottom:'2px'}}>
                                            Informações uteis para 
                                            manteres-te informado, acompanhe aqui
                                            </p>
                                            <p className="center describe-section hide-on-med-and-up" style={{marginBottom:'8px'}}>
                                            Mantenha-se informado sobre os diversos assuntos
                                            da área em questão. Apresentações, Discursos e muito mais.
                                            </p>
                                             {/*=========-------======== */}

                                            <p className="center describe-section hide-on-small-and-down" style={{marginBottom:'8px'}}>
                                            Mantenha-se informado sobre os diversos assuntos<br />
                                            da área em questão. Apresentações, Discursos e muito mais.
                                            </p>
                                        </div>

                                      
                                        { GetAllPost() }
                                      
                                       

                                      

                                    

                                        

                                       {/* <div className="col l12 s12 m12 center card-btn-ver">

                                           <Link to="Posts" onClick={()=>scroll.scrollToTop()}> 
                                                <button className="ver-mais-btn">VER MAIS</button> 
                                           </Link>

                                        </div> */}

                                   

                                </div>

                            </section>

                        </div>
                        
                      {/*  <div className="col l12 s12 m12 white section-007">


                            <section className="section-07">

                                <div className="row">

                                    <div className="col l12 s12 m12"
                                         data-aos="zoom-in-up"
                                         data-aos-duration="1000"
                                    >
                                         <hr className="barra" style={{width:'80px', margin:'auto'}}/>
                                        <p className="center title-section" style={{marginBottom:'2px'}}                   
                                        >
                                        O que os nossos clientes <br />
                                        dizem sobre nós ?
                                        </p>
                                        <p className="center describe-section" style={{marginBottom:'8px'}}>
                                        Veja os diferentes pontos de vista ditos pelos nossos clientes<br />
                                        sobre os nossos serviços.
                                        </p>
                                    </div>

                                    <div className="col l8 offset-l2 s12 m12">

                                        <Slider {...TestemunhoSlide} className="hide-on-small-and-down">

                                            <div>

                                                <p className="center testemunho">
                                                    
                                                    <span><i className="fa fa-quote-left fa-lg" aria-hidden="true" /></span>
                                                    A Gestão de Fundos é uma instituição com relações fortes com seus clientes,<br />
                                                    ela possuí uma maneira muito eficaz e apropriada para resolver os nossos problemas.<br />
                                                    Ouvimos o que ela diz-nos e prestamos bastante atenção quando ela nos diz em nossas pesquisas com clientes.
                                                    <span><i className="fa fa-quote-right fa-lg" aria-hidden="true" /></span>
                                                </p>

                                            </div>

                                            <div>

                                                <p className="center testemunho">
                                                 
                                                    <span><i className="fa fa-quote-left fa-lg" aria-hidden="true" /></span>
                                                    A Gestão de Fundos foi capaz de atende-nos de imediato, após a  <br />solicitação dos seus serviços
                                                    continuaremos a trabalhar com ela, pois a Gestão de Fundos tem o que nós precisamos para resolver os nossos problemas.
                                                    <span><i className="fa fa-quote-right fa-lg" aria-hidden="true" /></span>
                                                </p>

                                            </div>

                                        </Slider>

                                         <Slider {...TestemunhoSlide} className="hide-on-med-and-up">

                                            <div>

                                                <p className="center testemunho">
                                                    
                                                    <span><i className="fa fa-quote-left fa-lg" aria-hidden="true" /></span><br />
                                                    A Gestão de Fundos é uma instituição com relações fortes com seus clientes,
                                                    ela possuí uma maneira muito eficaz e apropriada para resolver os nossos problemas.
                                                    Ouvimos o que ela diz-nos e prestamos bastante atenção quando ela nos diz em nossas pesquisas com clientes.
                                                    <br /><span><i className="fa fa-quote-right fa-lg" aria-hidden="true" /></span>
                                                </p>

                                            </div>

                                            <div>

                                                <p className="center testemunho">
                                                 
                                                    <span><i className="fa fa-quote-left fa-lg" aria-hidden="true" /></span><br />
                                                    A Gestão de Fundos foi capaz de atende-nos de imediato, após a  solicitação dos seus serviços
                                                    continuaremos a trabalhar com ela, pois a Gestão de Fundos tem o que nós precisamos para resolver os nossos problemas.
                                                    <br /><span><i className="fa fa-quote-right fa-lg" aria-hidden="true" /></span>
                                                </p>

                                            </div>

                                        </Slider>
                                    
                                    </div>

                                </div>

                            </section>

                                    </div> */}


                        <div className="col l12 s12 m12 section-008">

                            <section className="section-08" id="CONTACTO">

                                <div className="row">

                                    <div className="col l5 s12 box-endereco"
                                          data-aos="fade-right"
                                          data-aos-duration="3000"
                                    >

                                        <div className="box-det">
                                            <p className="title-section-2"><i className="fa fa-map-marker fa-lg" aria-hidden="true" style={{color:'#C51F29'}} />
                                            Nossa Localização
                                            </p>
                                            <p className="detalhe-en">Rua Quenguela-Norte, Residência S/N <br />
                                                Bairro Cabolombo/Quifica, <br />
                                                Destrito Urbano do Talatona - Luanda</p>


                                        </div>

                                        <div className="box-det">
                                            <p className="title-section-2"><i className="fa fa-phone fa-lg" aria-hidden="true" style={{color:'#C51F29'}} />Nosso Telefone</p>
                                            <p className="detalhe-en">+244 937 028 555
                                            
                                            </p>


                                        </div>

                                        <div className="box-det">
                                            <p className="title-section-2"><i className="fa fa-envelope " aria-hidden="true" style={{color:'#C51F29'}} />Nosso Correio Electronico</p>
                                            <p  className="detalhe-en">geral@gestaodefundos.co.ao</p>


                                        </div>

                                    </div>

                                    <div className="col l8 offset-l4 s12" style={{padding:'0'}}>

                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3314.284268043993!2d13.174939139023238!3d-8.937970983732935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1a51f54d1876874d%3A0x64dc6832fc27fb95!2sRua%20Quenguela%20Norte%2C%20Belas!5e0!3m2!1spt-PT!2sao!4v1638242737526!5m2!1spt-PT!2sao" allowFullScreen loading="lazy" 
                                          data-aos="fade-left"
                                          data-aos-duration="1000"
                                          title="Endereço Gestão de Fundos"
                                    />


                                    </div>

                                </div>

                            </section>

                        </div>

                        <div className="col l12 s12 m12 white" style={{height:'132px'}}>

                        </div>
                       
                    </div>

                </React.Fragment>

                
            )
            
        }


export default Home