
const Services = [

    {
        Id:1,
        Icon: "./img/service01.png",
        Title:"Fundos de Pensões",
        Describe:{
            Line01: "Gestão, administração e respectivamente a ",
            Line02: "elaboração de estudos, de planos de pensões, de",
            Line03: "planos técnicos-actuariais e a prestação de serviços",
            Line04: "actuariais e de consultoria financeira"
        }
    }, 
    {
        Id:2,
        Icon: "./img/service02.png",
        Title:"Fundos de Investimentos",
        Describe:{
            Line01: "Gestão, administração e representação de ",
            Line02: "fundos de investimentos, de todo o tipo e natureza,",
            Line03: "assim como o desenvolvimento de actividades conexas.",
            Line04: ""
        }
    },
    { 
        Id:3,
        Icon: "./img/service03.png",
        Title:"Fundos de Patrimónios",
        Describe:{
            Line01: "Gestão, administração e representação de fundos, ",
            Line02: "de patrimónios, de activos e de valores mobiliários",
            Line03: "e imobiliários, de todo o tipo e natureza, assim",
            Line04: "como o desenvolvimentode actividade conexas"
        }
    }
]

export default Services