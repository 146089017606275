const postAPI = [

    {

        Id:1984567,
        Titulo: "Lista de Penseionistas para prova de vida 2024",
        Cover:"./img/docs.jpg",
        Categoria:"DOCUMENTOS",
        Data:"27 de Fevereiro de 2024",
       
    },

   /* {

        Id:1974090,
        Titulo: "Lista de Futuros Candidatos",
        Cover:"./img/picture05.jpg",
        Categoria:"NOTÍCIA",
        Data:" 05 Novembro 2021",
        Descricao:`
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
        tr, sed diam nonumy`,
       


    },

    {

        Id:1984090,
        Titulo: "Relatório de reclamações",
        Cover:"./img/news6.jpg",
        Categoria:"R.DE RECLAMAÇÕES",
        Data:" 10 Dezembro 2021",
        Descricao:`
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
        tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy`,
       


    }*/

]

export default postAPI