import React, { Component } from 'react'
import './servico.css'
import pictures from '../../pictures'
import AOS from 'aos'
import '../../styles/aos-css/aos.css'
import Slider from "react-slick"
import "../../styles/slick/slick.css"; 

class Servico extends Component {

    render() {

     AOS.init()

      const sett ={

        dots:true,
        infinite:true,
        speed: 2500,
        slidesToShow:1,
        slidesToScrooll:1,
        autoplay:true,
        autoplaySpeed:1000,
        pauseOnHover:true
    }


        return (

            <React.Fragment>

                <div className="row servico" style={{ marginBottom: '0' }}>

                    <header>
                        <div className="col l12 section-into s12">

                            <div className="row">

                                <div className="col l7 box-picture01 s12"
                                    data-aos="fade-right"
                                    data-aos-duration="1000"
                                >
                                    <img src={pictures.Vetor01} alt="vetor" className="vetor01 hide-on-small-and-down" />
                                    <div className="cover"></div>

                                    <img src={pictures.News4} alt="News" />




                                </div>

                                <div className="col l6 box-into offset-l5 s12">

                                    <hr className="barra" />

                                    <p className="detalhe-into hide-on-small-and-down"
                                        data-aos="fade-up"
                                        data-aos-duration="1000"
                                    >

                                        O que fazemos ?<br />
                                        Exercemos actividades de <br /> constituição, gestão, <br />  administração e representação <br />  de fundo de pensões.
                                                 


                                    </p>

                                    <p className="detalhe-into hide-on-med-and-up"
                                        data-aos="fade-up"
                                        data-aos-duration="1000"
                                    >

                                        O que fazemos ?
                                        Exercemos actividades de gestão, administração e representação de fundo de pensões.
                                                   
                                        


                                    </p>


                                    <div className="box-desc"
                                        data-aos="fade-up"
                                        data-aos-duration="1000"
                                    >



                                        <p className="detalhe-into-2">
                                            O nosso objecto social está assente em três
                                            vectores.
                                        </p>


                                        <img src={pictures.Vetor02} alt="vetor" className="vetor02" />

                                    </div>




                                </div>


                            </div>

                        </div>

                    </header> 
                    <div className="col l6 section-right offset-l6 s12"></div>



                  <section>

                        <div className="col l12 m12 s12 service-section white">

                            <div className="row reset box-service-s">


                                <hr className="barra" style={{ width: '80px', margin: 'auto' }} />
                                <p className="title-section center hide-on-med-and-up"
                                 data-aos="zoom-in-up"
                                 data-aos-duration="1000"
                                >

                                    Conheça os vectores <br />em que
                                    o nosso objecto social encontra-se<br />
                                    em assente.

                                </p>

                                <p className="title-section center hide-on-small-and-down"
                                 data-aos="zoom-in-up"
                                 data-aos-duration="1000"
                                >

                                    Conheça os vectores em que<br />
                                    o nosso objecto social encontra-se<br />
                                    em assente.

                                </p>

                                

                                <p className="detalhe-into-2 black-text t-s center">" É o que fazemos hoje, que nos define amanhã. "</p>


                                <div className="col l12 s12 m12 all-box-servico">

                                    <div className="row hide-on-small-and-down">

                                        <div className="col l4 s12"
                                             data-aos="fade-right"
                                             data-aos-duration="1000"
                                        >

                                            <div className="card-servico-s">
                                                <p className="center"><img src={pictures.Servico1} alt="icone-servico" className="img-service" /></p>
                                                <p className="title center">FUNDOS DE PENSÕES</p>
                                                <p className="desc-servico">
                                                    O exercício da actividade de constituição, gestão,
                                                    administração e representação de fundos de pensões, de todo o tipo e natureza, assim como o desenvolvimento de actividades conexas, respectivamente :
                                                </p>

                                                <ul className="list-service">
                                                    <li><i className="fa fa-circle red-text" aria-hidden="true"></i>Elaboração de Estudos;</li>
                                                    <li><i className="fa fa-circle red-text" aria-hidden="true"></i>Planos de pensões;</li>
                                                    <li><i className="fa fa-circle red-text" aria-hidden="true"></i>Planos técnicos-actuariais;</li>
                                                    <li><i className="fa fa-circle red-text" aria-hidden="true"></i>Prestação de serviços actuariais e de
                                                        consultoria financeira nessa área.</li>
                                                </ul>
                                            </div>

                                        </div>

                                        <div className="col l4 s12" 
                                             data-aos="fade-up"
                                             data-aos-duration="1000"
                                        >

                                            <div className="card-servico-s card-center">
                                                <p className="center"><img src={pictures.Servico3} alt="icone-servico" className="img-service" /></p>
                                                <p className="title center">FUNDOS DE PATRIMÓNIOS</p>
                                                <p className="desc-servico">
                                                    O exercício da actividade de constituição, gestão,
                                                    administração e representação de fundos de patrimónios, de todo o tipo e natureza, assim como o desenvolvimento de actividades conexas, respectivamente :
                                                </p>

                                                <ul className="list-service">
                                                    <li><i className="fa fa-circle red-text" aria-hidden="true"></i>Fundos de patrimónios;</li>
                                                    <li><i className="fa fa-circle red-text" aria-hidden="true"></i>Activos;</li>
                                                    <li><i className="fa fa-circle red-text" aria-hidden="true"></i>Valores mobiliários;</li>
                                                    <li><i className="fa fa-circle red-text" aria-hidden="true"></i>Valores Imobiliários.</li>
                                                </ul>
                                            </div>

                                        </div>

                                        <div className="col l4 s12"
                                             data-aos="fade-left"
                                             data-aos-duration="1000"
                                        >

                                            <div className="card-servico-s">
                                                <p className="center"><img src={pictures.Servico2} alt="icone-servico" className="img-service" /></p>
                                                <p className="title center">FUNDOS DE INVESTIMENTOS</p>
                                                <p className="desc-servico">
                                                    O exercício da actividade de constituição, gestão,
                                                    administração e representação de fundos de investimento, de todo o tipo e natureza, assim como o desenvolvimento de actividades conexas.
                                                </p>

                                            </div>

                                        </div>



                                    </div>

                                     <div className="row hide-on-med-and-up">


                                        <Slider {...sett}>

                                        <div className="col l4 s12"
                                             data-aos="fade-right"
                                             data-aos-duration="1000"
                                        >

                                            <div className="card-servico-s">
                                                <p className="center p-icone"><img src={pictures.Servico1} alt="icone-servico" className="img-service" /></p>
                                                <p className="title center">FUNDOS DE PENSÕES</p>
                                                <p className="desc-servico">
                                                    O exercício da actividade de constituição, gestão,
                                                    administração e representação de fundos de pensões, de todo o tipo e natureza, assim como o desenvolvimento de actividades conexas, respectivamente :
                                                </p>

                                                <ul className="list-service">
                                                    <li><i className="fa fa-circle red-text" aria-hidden="true"></i>Elaboração de Estudos;</li>
                                                    <li><i className="fa fa-circle red-text" aria-hidden="true"></i>Planos de pensões;</li>
                                                    <li><i className="fa fa-circle red-text" aria-hidden="true"></i>Planos técnicos-actuariais;</li>
                                                    <li><i className="fa fa-circle red-text" aria-hidden="true"></i>Prestação de serviços actuariais e de
                                                        consultoria financeira nessa área.</li>
                                                </ul>
                                            </div>

                                        </div>

                                        <div className="col l4 s12" 
                                             data-aos="fade-up"
                                             data-aos-duration="1000"
                                        >

                                            <div className="card-servico-s card-center">
                                                <p className="center p-icone"><img src={pictures.Servico3} alt="icone-servico" className="img-service" /></p>
                                                <p className="title center">FUNDOS DE <br />PATRIMÓNIOS</p>
                                                <p className="desc-servico">
                                                    O exercício da actividade de constituição, gestão,
                                                    administração e representação de fundos de patrimónios, de todo o tipo e natureza, assim como o desenvolvimento de actividades conexas, respectivamente :
                                                </p>

                                                <ul className="list-service">
                                                    <li><i className="fa fa-circle red-text" aria-hidden="true"></i>Fundos de patrimónios;</li>
                                                    <li><i className="fa fa-circle red-text" aria-hidden="true"></i>Activos;</li>
                                                    <li><i className="fa fa-circle red-text" aria-hidden="true"></i>Valores mobiliários;</li>
                                                    <li><i className="fa fa-circle red-text" aria-hidden="true"></i>Valores Imobiliários.</li>
                                                </ul>
                                            </div>

                                        </div>

                                        <div className="col l4 s12"
                                             data-aos="fade-left"
                                             data-aos-duration="1000"
                                        >

                                            <div className="card-servico-s">
                                                <p className="center p-icone"><img src={pictures.Servico2} alt="icone-servico" className="img-service" /></p>
                                                <p className="title center">FUNDOS DE INVESTIMENTOS</p>
                                                <p className="desc-servico">
                                                    O exercício da actividade de constituição, gestão,
                                                    administração e representação de fundos de investimento, de todo o tipo e natureza, assim como o desenvolvimento de actividades conexas.
                                                </p>

                                            </div>

                                        </div>

                                        </Slider>



                                    </div>

                                </div>

                                <div className="col l12 s12 m12 white" style={{ height: '132px' }}> </div>

                            </div>

                        </div>

                    </section> 

                </div>

            </React.Fragment>
        )
    }
}

export default Servico