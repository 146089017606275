import React,{useState, useEffect} from 'react'
import pictures from '../../pictures'
import './menu.css'

import { NavLink, Link } from 'react-router-dom'

function Menu(){

   const [menu, setMenu] = useState(false)
   const [status, setStatus]= useState(0)

   const openMenu = ()=>{

        if(status===0){

            setMenu(true)
            setStatus(1)

        }else{
            setMenu(false)
            setStatus(0)
        }

   }

   useEffect(()=>{
      
   })


        return(

            <React.Fragment>
                
                <nav className="z-depth-0 reset">
                  

                        <div className={!menu?'menu-mobile hide-menu':'menu-mobile show-menu'}>

                        <ul className="list-link link-mobile">
                            <li><NavLink exact to="/" activeClassName="active-link" onClick={()=>{openMenu()}}>HOME</NavLink></li>
                            <li><NavLink to="/servico" activeClassName="active-link" onClick={()=>{openMenu()}}> SERVIÇOS </NavLink></li>
                            <li><NavLink to="/fundos_sobre_gestao"  activeClassName="active-link" onClick={()=>{openMenu()}}>FUNDOS SOB GESTÃO</NavLink></li>
                            <li><NavLink to="/posts"  activeClassName="active-link" onClick={()=>{openMenu()}}>PUBLICAÇÕES</NavLink></li>
                            <li><NavLink to="/contacto" activeClassName="active-link" onClick={()=>{openMenu()}}>CONTACTO</NavLink></li>
                            <li><NavLink to="/sobre" activeClassName="active-link" onClick={()=>{openMenu()}}>SOBRE</NavLink></li>

                            

                        </ul>

                        </div>

                   
                    <div className="nav-wrapper">

                        <Link to="/" className="brand-logo left"><img src={pictures.logo} alt="Gestão de Fundos" className="logo"/></Link>
                        <button className='right btn-open-close hide-on-med-and-up' onClick={()=>{openMenu()}}><i className={!menu?"fa fa-bars fa-2x":"fa fa-close fa-2x"} aria-hidden="true" /></button>
                       
                        <ul className="right list-link hide-on-med-and-down">
                            <li><NavLink exact to="/" activeClassName="active-link">HOME</NavLink></li>
                            <li><NavLink to="/servico" activeClassName="active-link"> SERVIÇOS </NavLink></li>
                            <li><NavLink to="/fundos_sobre_gestao"  activeClassName="active-link">FUNDOS SOB GESTÃO</NavLink></li>
                            <li><NavLink to="/posts"  activeClassName="active-link">PUBLICAÇÕES</NavLink></li> 
                            <li><NavLink to="/contacto" activeClassName="active-link">CONTACTO</NavLink></li>
                            <li className="link-sobre"><NavLink to="/sobre" activeClassName="active-link">SOBRE</NavLink></li>

                            <li className="li-btn btn-lang">
                                <button title='Traduzir Para  Português' className='pt'>PT</button>
                                
                            </li>
                            <li className="li-btn btn-lang en-opc">
                                
                                <button title='Traduzir Para Inglês' className='en'>EN</button>
                                
                            </li>
                        </ul>

                    </div>
                </nav>

            </React.Fragment>
        )
    }
export default Menu