
// === 2019

/*
import FILE_1 from "../docs/2019/Fundo_de_Pensoes_da_Sogester.pdf"
import FILE_2 from "../docs/2019/Fundo_de_Pensoes_de_Catoca.pdf"
import FILE_3 from "../docs/2019/Fundo_de_Pensoes_do_Banco_Sol.pdf"
import FILE_4 from "../docs/2019/Fundo_de_Pensoes_Multiplanos_5_Estrelas.pdf"
import FILE_5 from "../docs/2019/Futuro_Fundo_de_Pensões.pdf"
import FILE_6 from "../docs/2019/Sociedade_Gestora.pdf"*/

// === 2020
/*import FILE_7 from "../docs/2020/Relatorio_e_Contas_Fundo_de_Pensoes_de_Catoca.pdf"
import FILE_8 from "../docs/2020/Relatorio_e_Contas_Fundo_de_Pensoes_do_Banco_Sol.pdf"
import FILE_9 from "../docs/2020/Relatorio_e_Contas_Fundo_de_Pensoes_Multiplanos_5_Estrelas.pdf"
import FILE_10 from "../docs/2020/Relatorio_e_Contas_Futuro_Fundo_de_Pensoes.pdf"
import FILE_11 from "../docs/2020/Relatorio_e_Contas_da_Sociedade_Gestora.pdf"*/


// === 2021

/*import FILE_16 from "../docs/2021/Fundo_de_Pensoes_de_Catoca_Frente.pdf"
import FILE_17 from "../docs/2021/Fundo_de_Pensoes_de_Catoca_Verso.pdf"
import FILE_18 from "../docs/2021/Fundo_de_Pensoes_Sogester.pdf"
import FILE_19 from "../docs/2021/Fundo_de_Pensoes_Sogester_Verso.pdf"
import FILE_20 from "../docs/2021/Futuro_Fundo_de_Pensoes_Frente.pdf"
import FILE_21 from "../docs/2021/Futuro_Fundo_de_Pensoes_Verso.pdf"
import FILE_22 from "../docs/2021/Sociedade_Gestora_Frente.pdf"
import FILE_23 from "../docs/2021/Sociedade_Gestora_Verso.pdf"
import FILE_24 from "../docs/2021/Fundo_de_Pensoes_Banco_Sol.pdf"
import FILE_25 from "../docs/2021/Multiplanos_5_Estrelas.pdf"*/


// === 2022

import FILE_12 from "../docs/2022/Demonstracoes_Financeiras_da_GF_SA_2022_0591.pdf"
import FILE_13 from "../docs/2022/FFP_Notas_as_Contas_2022.pdf"
import FILE_14 from "../docs/2022/FPBSOL_Notas_as_Contas_2022.pdf"
import FILE_15 from "../docs/2022/FPC_Notas_as_contas_2022.pdf"



const Lists = [

    /*{

        Id:1,
        Titulo: "Fundo de Pensões da Sogester",
        Cover:"./img/doc1.jpg",
        Categoria:"DOCUMENTOS - 019",
        Data:"30 Maio 2023",
        link:FILE_1,
        year:2019
       
       
    },

   {

        Id:2,
        Titulo: "Fundo de Pensões de Catoca",
        Cover:"./img/doc1.jpg",
        Categoria:"DOCUMENTOS - 019",
        Data:"30 Maio 2023",
        link:FILE_2,
        year:2019
     
       


    },

    {

        Id:3,
        Titulo: "Fundo de Pensões do Banco Sol",
        Cover:"./img/doc1.jpg",
        Categoria:"DOCUMENTOS - 019",
        Data:"30 Maio 2023",
        link:FILE_3,
        year:2019
       


    },

    {

        Id:4,
        Titulo: "Fundo de Pensões Multiplanos 5 Estrelas",
        Cover:"./img/doc1.jpg",
        Categoria:"DOCUMENTOS - 019",
        Data:"30 Maio 2023",
        link:FILE_4,
        year:2019
      


    },

    {

        Id:5,
        Titulo: "Futuro - Fundo de Pensões",
        Cover:"./img/doc1.jpg",
        Categoria:"DOCUMENTOS - 019",
        Data:"30 Maio 2023",
        link:FILE_5,
        year:2019
       


    },
    {

        Id:6,
        Titulo: "Sociedade Gestora",
        Cover:"./img/doc1.jpg",
        Categoria:"DOCUMENTOS - 019",
        Data:"30 Maio 2023",
        link:FILE_6,
        year:2019
       


    },
    //============ 2020
    {
        Id:7,
        Titulo: "Relatório e Contas - Fundo de Pensões de Catoca",
        Cover:"./img/doc2.jpg",
        Categoria:"DOCUMENTOS - 020",
        Data:"30 Maio 2023",
        link:FILE_7,
        year:2020
    },
    {
        Id:8,
        Titulo: "Relatório e Contas - Fundo de Pensões do Banco Sol",
        Cover:"./img/doc2.jpg",
        Categoria:"DOCUMENTOS - 020",
        Data:"30 Maio 2023",
        link:FILE_8,
        year:2020
    },
    {
        Id:9,
        Titulo: "Relatório e Contas - Fundo de Pensões Multiplanos 5 Estrelas",
        Cover:"./img/doc2.jpg",
        Categoria:"DOCUMENTOS - 020",
        Data:"30 Maio 2023",
        link:FILE_9,
        year:2020
    },
    {
        Id:10,
        Titulo: "Relatório e Contas - Futuro-Fundo de Pensões",
        Cover:"./img/doc2.jpg",
        Categoria:"DOCUMENTOS - 020",
        Data:"30 Maio 2023",
        link:FILE_10,
        year:2020
    },
    {
        Id:11,
        Titulo: "Relatório e Contas da Sociedade Gestóra",
        Cover:"./img/doc2.jpg",
        Categoria:"DOCUMENTOS - 020",
        Data:"30 Maio 2023",
        link:FILE_11,
        year:2020
    },*/

    // ========== 2022

    {
        Id:12,
        Titulo: "Demonstrações Financeiras da GF SA",
        Cover:"./img/doc3.jpg",
        Categoria:"DOCUMENTOS - 022",
        Data:"30 Maio 2023",
        link:FILE_12,
        year:2022
    },
    {
        Id:13,
        Titulo: "FFP Notas as Contas",
        Cover:"./img/doc3.jpg",
        Categoria:"DOCUMENTOS - 022",
        Data:"30 Maio 2023",
        link:FILE_13,
        year:2022
    },
    {
        Id:14,
        Titulo: "FPBSOL Notas as Contas",
        Cover:"./img/doc3.jpg",
        Categoria:"DOCUMENTOS - 022",
        Data:"30 Maio 2023",
        link:FILE_14,
        year:2022
    },
    /*{
        Id:15,
        Titulo: "FPC Notas as contas",
        Cover:"./img/doc3.jpg",
        Categoria:"DOCUMENTOS - 022",
        Data:"30 Maio 2023",
        link:FILE_15,
        year:2022
    }*/
//============= 2021
    /*{
        Id:16,
        Titulo: "Fundo de Pensões de Catoca - Frente",
        Cover:"./img/doc4.jpeg",
        Categoria:"DOCUMENTOS - 021",
        Data:"30 Maio 2023",
        link:FILE_16,
        year:2021
    },
    {
        Id:17,
        Titulo: "Fundo de Pensões de Catoca - Verso",
        Cover:"./img/doc4.jpeg",
        Categoria:"DOCUMENTOS - 021",
        Data:"30 Maio 2023",
        link:FILE_17,
        year:2021
    },
    {
        Id:18,
        Titulo: "Fundo de Pensões Sogester - Frente",
        Cover:"./img/doc4.jpeg",
        Categoria:"DOCUMENTOS - 021",
        Data:"30 Maio 2023",
        link:FILE_18,
        year:2021
    },
    {
        Id:19,
        Titulo: "Fundo de Pensões Sogester - Verso",
        Cover:"./img/doc4.jpeg",
        Categoria:"DOCUMENTOS - 021",
        Data:"30 Maio 2023",
        link:FILE_19,
        year:2021
    },
    {
        Id:20,
        Titulo: "Futuro-Fundo de Pensões - Frente",
        Cover:"./img/doc4.jpeg",
        Categoria:"DOCUMENTOS - 021",
        Data:"30 Maio 2023",
        link:FILE_20,
        year:2021
    },
    {
        Id:21,
        Titulo: "Futuro-Fundo de Pensões - Verso",
        Cover:"./img/doc4.jpeg",
        Categoria:"DOCUMENTOS - 021",
        Data:"30 Maio 2023",
        link:FILE_21,
        year:2021
    },
    {
        Id:22,
        Titulo: "Sociedade Gestora - Frente",
        Cover:"./img/doc4.jpeg",
        Categoria:"DOCUMENTOS - 021",
        Data:"30 Maio 2023",
        link:FILE_22,
        year:2021
    },
    {
        Id:23,
        Titulo: "Sociedade Gestora - Verso",
        Cover:"./img/doc4.jpeg",
        Categoria:"DOCUMENTOS - 021",
        Data:"30 Maio 2023",
        link:FILE_23,
        year:2021
    },
    {
        Id:24,
        Titulo: "Fundo de Pensões - Banco Sol",
        Cover:"./img/doc4.jpeg",
        Categoria:"DOCUMENTOS - 021",
        Data:"30 Maio 2023",
        link:FILE_24,
        year:2021
    },
    {
        Id:25,
        Titulo: "Multiplanos 5 Estrelas",
        Cover:"./img/doc4.jpeg",
        Categoria:"DOCUMENTOS - 021",
        Data:"30 Maio 2023",
        link:FILE_25,
        year:2021
    },*/


]

export default Lists