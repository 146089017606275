const clientes = [

    {

        Id:2,
        Nome:"Catoca - Fundo de Pensões",
        Logo:"./img/catoca2.png",
        Link:"https://www.gestaodefundos.com/Catoca/"
       

    },
    
    {

        Id:3,
        Nome:"Sogester",
        Logo:"./img/sogester2.png",
        Link:"https://www.gestaodefundos.com/Sogester/"
      
    } ,

    {

        Id:1,
        Nome:"Futuro - Fundo de Pensões",
        Logo:"./img/futuro.png",
        Link:"#"

    },

    {
        Id:4,
        Nome:"Banco Sol - Fundo de Pensões",
        Logo:"./img/bancosol2.png",
        Link:"https://www.gestaodefundos.com/BancoSol/"
    
    },
     
    {

        Id:5,
        Nome:"Multiplanos 5 Estrelas",
        Logo:"./img/multiplanos.png",
        Link:"#"

    } 

]

export default clientes